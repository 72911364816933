<template>
    <div class="custom-box">
        <div class="keyword-select" v-if="addStep === 1">
            <el-select v-model="planValue" placeholder="请选择一个计划" style="margin-right: 20px;">
                <el-option
                        v-for="item in planOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="unitValue" placeholder="请选择一个单元">
                <el-option
                        v-for="item in unitOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="select-keyword-table" v-if="addStep === 2">
            <div class="keyword-select">
                <el-select v-model="planValue" placeholder="请选择计划名称" style="margin-right: 20px;">
                    <el-option
                            v-for="item in planOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="unitValue" placeholder="请选择单元名称">
                    <el-option
                            v-for="item in unitOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="select-table">
                <div class="is-selected-tb">
                    <el-tabs v-model="activeTab">
                        <el-tab-pane label="商品推词" name="first">
                            <el-scrollbar :native="false" class="el-is-selected-tb">
                                <el-table :data="goodsKeyWordList" border style="width: 100%; flex: 1; height: calc(100% - 50px);" size="medium"
                                          :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#333333', background: '#F5F5F5'}"
                                          :cell-style="{fontSize: '16px',color: '#333333'}">
                                    <el-table-column
                                            type="selection"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column prop="key_word" align="center" label="关键词"></el-table-column>
                                    <el-table-column prop="search_num" align="center" label="搜索量"></el-table-column>
                                    <el-table-column prop="average_bid" align="center" label="平均出价"></el-table-column>
                                    <el-table-column prop="click_rate" align="center" label="点击率（%）" ></el-table-column>
                                    <el-table-column prop="conversion_rate" align="center" label="转化率（%）" ></el-table-column>
                                    <el-table-column prop="recommended_buying" align="center" label="推荐买词热度">
                                        <template slot-scope="scope">
                                            <img src="" alt="">
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-scrollbar>
                        </el-tab-pane>
                        <el-tab-pane label="手动输入" name="second">
                            <div class="manual-input">
                                <el-input style="width: 300px" placeholder="请输入词语" v-model="manualKeywordInput"></el-input>
                                <el-button style="margin-left: 20px" @click="addManualKeyword">添加</el-button>
                            </div>
                            <ul class="rule">
                                <li>
                                    <span>1. 出价范围是0.1~9999，最多一位小数</span>
                                </li>
                                <li>
                                    <span>2. 关键词中如果出现繁体字系统会自动转换成简体形式；</span>
                                </li>
                                <li>
                                    <span>3. 添加关键词时，系统会默认将关键词中的大写字母转化为小写字母。</span>
                                </li>
                            </ul>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="selected-tb">
                    <div class="table-btn">
                        <span>已选关键词</span>
                        <el-button @click="delToggleKeyword" class="table-top-btn">删除</el-button>
                    </div>
                    <el-scrollbar :native="false" class="el-selected-tb">
                        <el-table :data="selectedKeywordList" border style="width: 100%; flex: 1; height: calc(100% - 50px);" size="medium"
                                  :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#333333', background: '#F5F5F5'}"
                                  :cell-style="{fontSize: '16px',color: '#333333'}">
                            <el-table-column
                                    type="selection"
                                    width="50">
                            </el-table-column>
                            <el-table-column prop="key_word" align="center" label="关键词"></el-table-column>
                            <el-table-column prop="pc_price" align="center" label="PC出价">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.pc_price" placeholder="请输入内容" :value="scope.row.pc_price"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="competitiveness_index" align="center" label="竞争力指数"></el-table-column>
                            <el-table-column prop="bid_position" align="center" label="出价排名"></el-table-column>
                        </el-table>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <div class="box-bottom">
            <el-button class="btn-red" @click="saveKeywordOne" v-if="addStep === 1">确定</el-button>
            <el-button class="btn-red" @click="saveKeyword" v-if="addStep === 2">确定</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "addKeywordPage",
        props:['keyword'],
        data() {
            return {
                //计划筛选
                planOptions: [
                    {
                        value: '选项1',
                        label: '黄金糕'
                    }, {
                        value: '选项2',
                        label: '双皮奶'
                    }, {
                        value: '选项3',
                        label: '蚵仔煎'
                    }, {
                        value: '选项4',
                        label: '龙须面'
                    }, {
                        value: '选项5',
                        label: '北京烤鸭'
                    }],
                planValue: '',
                //单元筛选
                unitOptions: [
                    {
                        value: '选项1',
                        label: '黄金糕'
                    }, {
                        value: '选项2',
                        label: '双皮奶'
                    }, {
                        value: '选项3',
                        label: '蚵仔煎'
                    }, {
                        value: '选项4',
                        label: '龙须面'
                    }, {
                        value: '选项5',
                        label: '北京烤鸭'
                    }],
                unitValue: '',
                //商品推词表格数据
                goodsKeyWordList: [
                    {
                        key_word: '男鞋男鞋男',
                        search_num: '999',
                        average_bid: '33',
                        click_rate: '33',
                        conversion_rate: '33',
                        recommended_buying: '33',
                    },
                    {
                        key_word: '男鞋男鞋男',
                        search_num: '999',
                        average_bid: '33',
                        click_rate: '33',
                        conversion_rate: '33',
                        recommended_buying: '33',
                    },
                    {
                        key_word: '男鞋男鞋男',
                        search_num: '999',
                        average_bid: '33',
                        click_rate: '33',
                        conversion_rate: '33',
                        recommended_buying: '33',
                    },
                    {
                        key_word: '男鞋男鞋男',
                        search_num: '999',
                        average_bid: '33',
                        click_rate: '33',
                        conversion_rate: '33',
                        recommended_buying: '33',
                    }
                ],
                //已选关键词表格数据
                selectedKeywordList: [
                    {
                        id: '1',
                        key_word: '男鞋男鞋男1',
                        pc_price: '3',
                        competitiveness_index: '10',
                        bid_position: 'PC:1'
                    },
                    {
                        id: '2',
                        key_word: '男鞋男鞋男2',
                        pc_price: '3',
                        competitiveness_index: '10',
                        bid_position: 'PC:1'
                    },
                    {
                        id: '3',
                        key_word: '男鞋男鞋男3',
                        pc_price: '3',
                        competitiveness_index: '10',
                        bid_position: 'PC:1'
                    },
                ],
                //添加关键词步骤
                addStep: 1,
                activeTab: 'first',
                //手动输入关键词
                manualKeywordInput: ''
            }
        },
        methods: {
            //保存关键词第一步
            saveKeywordOne() {
                this.addStep = 2;
            },
            //保存关键词第二步
            saveKeyword() {
                console.log('1111',this.keyword)
                this.$emit('keyword',false);
            },
            //删除已选关键词
            delToggleKeyword() {

            },
            //保存手动添加的关键词
            addManualKeyword() {

            }
        }
    }
</script>

<style scoped lang="scss">
    .custom-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 60px;
        span {
            color: #989898;
            font-size: 18px;
            line-height: 1.5;
            margin-top: 20px;
        }
        .title {
            color: #333333;
            font-size: 24px;
            padding-bottom: 20px;
            margin-top: 0;
        }
        span:nth-child(4) {
            line-height: 27px;
        }
        .box-bottom {
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
            text-align: right;
            /deep/ .btn-red {
                padding: 17px 45px;
                border-radius: 6px;
            }
        }
        .CustomBudget-radio {
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
        }
        /deep/ .el-radio__input.is-checked .el-radio__inner {
            border-color: #FD4446;
            background: #FD4446;
        }
        /deep/ .el-radio__inner:hover {
            border-color: #FD4446;
        }
        /deep/ .el-radio__input.is-checked+.el-radio__label {
            color: #333;
        }
        .isShowActive {
            background: #FFECC8!important;
            border-color: #ffffff!important;
        }
        .select-keyword-table {
            width: 100%;
            /*height: 100%;*/
            height: calc(100% - 80px);
            .keyword-select {
                margin-bottom: 20px;
            }
            .select-table {
                width: 100%;
                height: calc(100% - 60px);
                .is-selected-tb {
                    float: left;
                    height: 100%;
                    width: 58%;
                    margin-right: 43px;
                    border: 1px solid #DFDFDF;
                    /deep/ .el-table--medium td, /deep/ .el-table--medium th {
                        padding: 18px 0;
                    }
                    /deep/ .el-tabs {
                        height: 100%;
                        /deep/ .el-tabs__header {
                            margin: 0;
                            .el-tabs__item {
                                padding-left: 20px;
                                padding-top: 10px;
                                font-weight: 500;
                            }
                        }
                        /deep/ .el-tabs__content {
                            height: calc(100% - 55px);
                            .el-tab-pane {
                                height: 100%;
                            }
                        }
                    }
                    .manual-input {
                        margin: 20px;
                    }
                    ul.rule {
                        margin: 0;
                        padding: 0 20px 0 40px;
                        list-style-type: none;
                        li {
                            span {
                                font-size: 16px;
                                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            }
                        }
                    }
                }
                .el-is-selected-tb {
                    height: 100%;
                    /deep/ .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
                .el-selected-tb {
                    /*height: 100%;*/
                    /deep/ .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
                .selected-tb {
                    width: calc(42% - 47px);
                    height: 100%;
                    float: left;
                    border: 1px solid #DFDFDF;
                    /deep/ .el-table--medium td, /deep/ .el-table--medium th {
                        padding: 14px 0;
                    }
                    .table-btn {
                        padding: 10px 20px;
                        span {
                            font-size: 20px;
                            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                            font-weight: 500;
                            color: #333333;
                            margin-right: 20px;
                        }
                        /deep/ .el-button {
                            padding: 7px 15px;
                        }
                    }
                    /deep/ .el-input__inner {
                        height: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    /deep/ .el-drawer {
        &.rtl {
            &:focus {
                outline: 0;
            }
        }
        .el-drawer__header {
            color: #333333;
            font-size: 24px;
            padding: 40px 20px 0 60px;
            margin-bottom: 0;
            font-weight: 500;
            span[role='heading'] {
                &:focus {
                    outline: 0;
                }
            }
        }
        .el-drawer__body {
            height: calc(100% - 74px);
        }
    }
    .el-tabs {
        /deep/ .el-tabs__header {
            margin: 0 0 32px;
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
            .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #DFDFDF;
            }
            .el-tabs__item {
                font-size: 20px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-bottom: 14px;
            }
        }
    }
</style>