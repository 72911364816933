import _ from "underscore";
import areaList from "@/assets/json/territory.json";
import {stuEndingCalc, stuEndingPromotion} from "@/utils/apis";
import { mapActions } from "vuex";
import Http from "@/utils/http";
export default {
    name: "PromotionPlan",
    data() {
        return {
            //是否显示修改输入框
            isShow: null,
            promotionPlanList: [],
            selectArr: [],
            //自定义预算
            customDrawer: false,
            customBudget: '',
            //时段 | 实时折扣系数
            timeDrawer: false,
            timeRadio: 1,
            tableData:[{
                title:'时段',
                '06':'1',
                '612':'',
                '1218':'',
                '1824':'',
            }],
            oneToSix: [
                {"type":0,"discount":100,"launch": 0,'timezone':'00:00-01:00','name':'oneToSix','index':0},
                {"type":1,"discount":100,"launch": 0,'timezone':'01:00-02:00','name':'oneToSix','index':1},
                {"type":2,"discount":100,"launch": 0,'timezone':'02:00-03:00','name':'oneToSix','index':2},
                {"type":3,"discount":100,"launch": 0,'timezone':'03:00-04:00','name':'oneToSix','index':3},
                {"type":4,"discount":100,"launch": 0,'timezone':'04:00-05:00','name':'oneToSix','index':4},
                {"type":5,"discount":100,"launch": 0,'timezone':'05:00-06:00','name':'oneToSix','index':5},
            ],
            sixToTwelve: [
                {"type":6,"discount":100,"launch":0,'timezone':'06:00-07:00','name':'sixToTwelve','index':0},
                {"type":7,"discount":100,"launch":0,'timezone':'07:00-08:00','name':'sixToTwelve','index':1},
                {"type":8,"discount":100,"launch":0,'timezone':'08:00-09:00','name':'sixToTwelve','index':2},
                {"type":9,"discount":100,"launch":0,'timezone':'09:00-10:00','name':'sixToTwelve','index':3},
                {"type":10,"discount":100,"launch":0,'timezone':'10:00-11:00','name':'sixToTwelve','index':4},
                {"type":11,"discount":100,"launch":0,'timezone':'11:00-12:00','name':'sixToTwelve','index':5},
            ],
            TwelveToEighteen: [
                {"type":12,"discount":100,"launch": 0,'timezone':'12:00-13:00','name':'TwelveToEighteen','index':0},
                {"type":13,"discount":100,"launch": 0,'timezone':'13:00-14:00','name':'TwelveToEighteen','index':1},
                {"type":14,"discount":100,"launch": 0,'timezone':'14:00-15:00','name':'TwelveToEighteen','index':2},
                {"type":15,"discount":100,"launch": 0,'timezone':'15:00-16:00','name':'TwelveToEighteen','index':3},
                {"type":16,"discount":100,"launch": 0,'timezone':'16:00-17:00','name':'TwelveToEighteen','index':4},
                {"type":17,"discount":100,"launch": 0,'timezone':'17:00-18:00','name':'TwelveToEighteen','index':5},
            ],
            EighteenToTwentyFour: [
                {"type":18,"discount":100,"launch": 0,'timezone':'18:00-19:00','name':'EighteenToTwentyFour','index':0},
                {"type":19,"discount":100,"launch": 0,'timezone':'19:00-20:00','name':'EighteenToTwentyFour','index':1},
                {"type":20,"discount":100,"launch": 0,'timezone':'20:00-21:00','name':'EighteenToTwentyFour','index':2},
                {"type":21,"discount":100,"launch": 0,'timezone':'21:00-22:00','name':'EighteenToTwentyFour','index':3},
                {"type":22,"discount":100,"launch": 0,'timezone':'22:00-23:00','name':'EighteenToTwentyFour','index':4},
                {"type":23,"discount":100,"launch": 0,'timezone':'23:00-24:00','name':'EighteenToTwentyFour','index':5}
            ],
            visible:false,
            hoverFormData: {
                show: false,
                timezone: null,
                discount: null,
                style: ''
            },
            clickFormData: {
                show: false,
                timezone: null,
                style: '',
                discount: 100,
                name: '',
                index: 0
            },
            isDisabled:false,
            rowTmp: null,
            //分页
            listPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 2,
            },
            moduleTemplate: null,
            goodsList: [],
            theCurrentPlanId: null,
            radio5: 0,
            drawer2: false,
            deleteCollections: [],
            btnStatus: true,
            planTotalCost: [],
            store_bugget: [],
            leftCount: null
        }
    },
    mounted() {
        this.getPromotionList();
        this.getSearchAds();
        this.goodsLists();
    },
    computed: {
        menus() {
            return this.$store.getters.dataMenuList;
        },
        isCompetition() {
            return this.$common.isCompetion() && this.leftCount !== null ;
        },
        eltagValue() {
            if (this.leftCount > 0) {
                return 'success';
            } else {
                return 'info';
            }
        }
    },
    methods: {
        ...mapActions([
            'setDataMenusList'
        ]),
        changeTimeSet (val) {
            if (Number(val) === 2) {
                this.clickFormData.discount = 100;
            }
        },
        // 更新状态
        operation_updateStatus(index1, index2,  status) {
            let param = {
                offset1: Number(index1),
                offset2: Number(index2),
                status: Number(status),
            }
            this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                if (res.code === 200) {
                    this.setDataMenusList();
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                console.log(err)
            })
        },
        deleteCollects(val) {
            this.deleteCollections = val;
        },
        confirmTimeSet() {
            let time_set = [];
            _.each(this.oneToSix, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });
            _.each(this.sixToTwelve, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });
            _.each(this.TwelveToEighteen, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });
            _.each(this.EighteenToTwentyFour, (item) => {
                if (item.launch === 1) {
                    time_set.push(item);
                }
            });

            time_set = _.map(time_set, (item) => {
                let tmp = {
                    time: Number(item.type) + 1,
                    discount: Number(item.discount)
                }
                return tmp;
            });
            if (time_set.length === 0) {
                this.$message.warning('请设置推广时间段');
                return ;
            }
            
            let param = {
                id: this.theCurrentPlanId,
                time_set: JSON.stringify(time_set)
            }
            this.$http.axiosGetBy(this.$api.ep_editep, param, (res) => {
                if (res.code === 200) {
                    this.$notify({
                        type: 'success',
                        message: `时间设置${res.msg}`,
                        duration: 1000,
                        onClose: () => {
                            this.getPromotionList();
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
            this.timeDrawer = false
        },
        goodsLists() {
            this.$http.axiosGet(this.$api.ep_goods, (res) => {
                if (res.code === 200) {
                    this.goodsList = res.data;
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        getSearchAds() {
            this.$http.axiosGet(this.$api.ep_kwmodule, (res) => {
                if (res.code === 200) {
                    this.moduleTemplate = {
                        time_data: JSON.parse(res.data.time_data),
                        area_data: JSON.parse(res.data.area_data),
                        people_data: JSON.parse(res.data.people_data),
                        store_offer_data: res.data.store_data.split('#')
                    }
                    this.btnStatus = false;
                    // console.log('mt', this.moduleTemplate);
                    this.$lockr.set('moduleTemplate', this.moduleTemplate);
                    this.store_bugget = this.moduleTemplate.store_offer_data;
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
            /*this.$http.axiosPost(this.$api.ep_getsd, {}, (res) => {
                if (res.code === 200) {
                    this.moduleTemplate = res.data;
                    console.log('数据', res.data);
                    this.btnStatus = false;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })*/
        },
        endingPromotion() {
            if (!this.deleteCollections.length) {
                return this.$message.warning("请选择要结束推广的计划")
            }
            const plan_ids = this.deleteCollections.map(item => {
                return item.id
            })
            stuEndingPromotion({id: plan_ids}).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: res.msg,
                        duration: 2000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    this.$message({
                        type: "warning",
                        message: res.msg,
                        duration: 3000,
                        onClose: () => {
                            window.location.reload()
                        }
                    })
                }
            }).catch(err => {
                console.log(err)
            })

        },
        /*endingPromotion() {
            /!*
            console.log('list', this.promotionPlanList);
            console.log('module', this.moduleTemplate);*!/
            if (this.promotionPlanList.length === 0) {
                this.$message.warning('请先创建搜索广告计划');
                return;
            }
            if (!this.moduleTemplate) {
                this.$message.warning('数据加载中,请稍后再试');
                return;
            }
			let time_num_arr = [];
            //获取时间
            let timeQueue = [];
            _.each(this.promotionPlanList, (item) => {
                let time_json =  JSON.parse(item.time_set);
				time_num_arr[item.id] = time_json.length;
                if (time_json) {
                    _.each(time_json, (val) => {
                        let tmp = {
                            time: val.time,
                            discount: parseFloat(val.discount),
                            plan_id: item.id
                        }
                        timeQueue.push(tmp);
                    });
                }
            });
            timeQueue = _.sortBy(timeQueue, 'time'); //将计划中的时间整合都一块重新排序
            let time_click_rate = this.moduleTemplate.time_data.click_rate;
            let time_tran_rate = this.moduleTemplate.time_data.tran_rate;
            let timeQueueT = [];
            _.each(timeQueue, (tItem) => {
                tItem.click_rate = parseFloat(time_click_rate[tItem.time - 1]);
                tItem.tran_rate = parseFloat(time_tran_rate[tItem.time - 1]);
                timeQueueT.push(tItem);
            });
            // console.log('时间队列', timeQueueT);
            if (timeQueueT.length === 0) {
                this.$message.warning('请先设置推广计划的时间段')
                return;
            }
            //获取关键词点击率和转化率
            let kwQueue = [];
            let allKeyWords = this.$store.getters.keywordData;

            let keyWordsInfo = this.$store.getters.keywordLists;
            _.each(keyWordsInfo, (kItem) => {
                let tmp = _.find(allKeyWords, { keyword_id: kItem.keyword_id });
                let kw = {
                    id: kItem.id,
                    k_id: kItem.keyword_id,
                    keyword: kItem.keyword,
                    plan_id: kItem.ep_id,
                    search_num: parseFloat(tmp.search_num),
                    click_rate: parseFloat(tmp.click_rate),
                    tran_rate: parseFloat(tmp.tran_rate),
                    left_show_num: Number(tmp.search_num),
                    offer_rank: tmp.offer_rank,
                    pc_price: Number(kItem.offer)
                }
                kwQueue.push(kw);
            });
            // console.log('关键词');
            if (kwQueue.length === 0) {
                this.$message.warning('请先设置关键词');
                return;
            }

            //获取商品的点击率和转化率
            // console.log('###',this.goodsList);
            let goodsQueue = [];
            _.each(this.promotionPlanList, (pItem) => {
                let goods_info = _.find(this.goodsList, {id: pItem.gi_id});
                let goods = {
                    goods_id: pItem.gi_id,
                    plan_id: pItem.id,
                    click_rate: parseFloat(goods_info.click_rate),
                    tran_rate: parseFloat(goods_info.tran_rate),
                    goods_price: goods_info.good_price
                }
                goodsQueue.push(goods);
            });
            // console.log('商品',goodsQueue);
            if (goodsQueue.length === 0) {
                this.$message.warning('请设置推广计划的商品');
                return;
            }
            // console.log('商品队列', goodsQueue);
            //获取地区的点击率和转化率
            let areaQueue = [];
            let area_click_rate = this.moduleTemplate.area_data.click_rate;
            let area_tran_rate = this.moduleTemplate.area_data.tran_rate;
            // console.log('地区');
            _.each(this.promotionPlanList, (item) => {
                let area_set = JSON.parse(item.area_set);
                if (area_set.length) {
                    _.each(area_set, (areaItem) => {
                        let area_parent = _.find(areaList, {id: areaItem[0]});
                        let area_info = _.find(area_parent.children, {id: areaItem[1]});
                        let tmp_click = _.find(area_click_rate, {name: area_info.name});
                        let tmp_tran = _.find(area_tran_rate, {name: area_info.name});
                        let tmp = {
                            plan_id: item.id,
                            name: area_info.name,
                            click_rate: tmp_click ? parseFloat(tmp_click.num) : 0,
                            tran_rate: tmp_tran ? parseFloat(tmp_tran.num) : 0
                        }
                        areaQueue.push(tmp);
                    });
                } else {
                    //地区全选
                    _.each(area_click_rate, (aItem, index) => {
                        let tmp = {
                            plan_id: item.id,
                            name: aItem.name,
                            click_rate: aItem.num,
                            tran_rate: area_tran_rate[index] ? area_tran_rate[index].num : 0
                        }
                        areaQueue.push(tmp);
                    });
                }

            });


            // console.log('地区', areaQueue);
            if (areaQueue.length === 0) {
                this.$message.warning('请设置推广计划的需要推广的地区');
                return;
            }

            // 关键词列表数据
            let kwTable = [];
            _.each(kwQueue, (item) => {
                let obj = {
                    id: item.id,
                    ep_id: item.plan_id,
                    show_num: 0,
                    click_num: 0,
                    tran_num: 0,
                    click_rate: 0,
                    tran_rate: 0,
                    total_cost: 0,
                    total_order_amount: 0,
                    avg_show_rank: 0,
                    roi: 0,
                    position_num: 0,
                    time_count: 0
                }
                kwTable[item.id] = obj;
            });
            let tablePlan = [];
            //获取日预算
            _.each(this.promotionPlanList, (item) => {
                let tmp = {
                    id: 0,
                    time_num: 0,
                    time_count: 0,
                    day_cost: 0,   //每日花费
                    daily_budget: 0,
                    show_num: 0,
                    click_num: 0,
                    click_rate: 0,
                    tran_rate: 0,
                    tran_num: 0,
                    total_cost: 0,
                    hour_cost: null, //匀速投放则不为空
                };
                tmp.id = item.id;
                if (Number(item.daily_budget)) {
                    tmp.daily_budget = Number(item.daily_budget);
                    tmp.day_cost = Number(item.daily_budget);
                } else {
                    tmp.daily_budget = 0;
                    tmp.day_cost = 0;
                }
                let time_set_tmp = JSON.parse(item.time_set);
                tmp.time_num = time_set_tmp.length;
                if (item.put_tactics === 0) { //为匀速投放
                    let hour_cost = Math.floor(item.daily_budget / time_set_tmp.length);
                    tmp.hour_cost = hour_cost;
                }
                tablePlan[item.id] = tmp;
            });
            //对比点击率
            let amount = Number(this.$lockr.get('amount'));
            let time_kw = [];
            let flag = false;  //跳出循环的标识
            let kw_collect = {};
            let rate_arr = [];
            let debdot = false;
            //根据计划中时间设置进行换算推广
            for(let i=0; i < timeQueueT.length; i++) {
                if (flag) {
                    break;
                }
                let time_tmp = timeQueueT[i];
                //当i=0时，说明又开始新的一天的消耗，如果有设置日预算的，就需要把每日准备花的钱等于每日预算
                if (tablePlan[time_tmp.plan_id].daily_budget && i === 0) {
                    tablePlan[time_tmp.plan_id].day_cost = tablePlan[time_tmp.plan_id].daily_budget;
                }
                //遍历所有计划中的关键词，关键词与时间段的差值
                for (let j=0; j < kwQueue.length; j++) {
                    let kwItem = kwQueue[j];
                    let pc_price = kwItem.pc_price;
                    if (time_tmp.plan_id !== kwItem.plan_id) {
                        continue;
                    }
                    //关键词剩余展现数为0，则直接跳过这个关键词
                    if (Number(kwItem.left_show_num) <= 0) {
                        continue;
                    }
                    let click_rate_tmp = 0;
                    let tran_rate_tmp = 0;
                    let time_kw_sub = this.$common.subtraction(time_tmp.click_rate, kwItem.click_rate)
                    click_rate_tmp = click_rate_tmp + this.zoneRate(parseFloat(time_kw_sub)); //调用自定义规则
                    let time_kw_sub_tran = this.$common.subtraction(time_tmp.tran_rate, kwItem.tran_rate);
                    tran_rate_tmp = tran_rate_tmp + this.zoneRate(time_kw_sub_tran);
                    //地区
                    let area_tmp = _.filter(areaQueue, {plan_id: kwItem.plan_id});
                    _.each(area_tmp, (item) => {
                        let area_kw_sub_c_item = this.$common.subtraction(item.click_rate, kwItem.click_rate);
                        click_rate_tmp = click_rate_tmp + this.zoneRate(area_kw_sub_c_item);
                        let area_kw_sub_t_item = this.$common.subtraction(item.tran_rate, kwItem.tran_rate);
                        tran_rate_tmp = tran_rate_tmp + this.zoneRate(area_kw_sub_t_item);
                    });
                    //人群
                    let peopleInfo = this.$store.getters.peopleOrientList;
                    let peopleArr = _.filter(peopleInfo, {ep_id: kwItem.plan_id});
                    _.each(peopleArr, (pitem) => {
                        if (pitem.status) {
                            let people_kw_sub_c_itme = this.$common.subtraction(pitem.click_rate, kwItem.click_rate);
                            click_rate_tmp = click_rate_tmp + this.zoneRate(people_kw_sub_c_itme);
                            let people_kw_sub_t_itme = this.$common.subtraction(pitem.tran_rate, kwItem.tran_rate);
                            tran_rate_tmp = tran_rate_tmp + this.zoneRate(people_kw_sub_t_itme);
                        }
                    });
                    //商品
                    _.each(goodsQueue, (item) => {
                        if (item.plan_id === kwItem.plan_id) {
                            let goods_kw_sub_c_item = this.$common.subtraction(item.click_rate, kwItem.click_rate);
                            click_rate_tmp = click_rate_tmp + this.zoneRate(goods_kw_sub_c_item);
                            let goods_kw_sub_t_item = this.$common.subtraction(item.tran_rate, kwItem.tran_rate);
                            tran_rate_tmp = tran_rate_tmp + this.zoneRate(goods_kw_sub_t_item);
                        }
                    });
                    let rank = kwItem.offer_rank[time_tmp.time - 1];
                    rank = _.sortBy(rank, (item) => {
                        return Number(-item);
                    });
                    //出价排行,乘以溢价系数
                    let position_num = this.$common.sortByIndex(rank, pc_price * (time_tmp.discount/100));
                    let add_rate = this.$common.addByRanking(position_num);
                    click_rate_tmp = click_rate_tmp + add_rate;
                    tran_rate_tmp = tran_rate_tmp + add_rate;

                    if (click_rate_tmp > 100) {
                        click_rate_tmp = 100;
                    }

                    if (tran_rate_tmp > 100) {
                        tran_rate_tmp = 100;
                    }

                    if (click_rate_tmp < 0) {
                        click_rate_tmp = 0.1;
                    }
                    if (tran_rate_tmp < 0) {
                        tran_rate_tmp = 0.1;
                    }

                    tablePlan[kwItem.plan_id].click_rate = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].click_rate + click_rate_tmp);
                    tablePlan[kwItem.plan_id].tran_rate = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].tran_rate + tran_rate_tmp);
                    tablePlan[kwItem.plan_id].time_count++;
                    kwTable[kwItem.id].click_rate = this.$common.toFormat2dot(kwTable[kwItem.id].click_rate + click_rate_tmp);
                    kwTable[kwItem.id].tran_rate = this.$common.toFormat2dot(kwTable[kwItem.id].tran_rate + tran_rate_tmp);
                    kwTable[kwItem.id].time_count++;
                    kwTable[kwItem.id].position_num += position_num;
                    //获取该时段的出价
                    let offer = 0;

                    if (position_num === rank.length) {
                        offer = rank[rank.length - 1];
                        offer = parseFloat(offer);
                    } else if (position_num === rank.length + 1) {
                        offer = pc_price;
                    } else {
                        offer = Number(position_num) === 1 ? parseFloat(rank[0]) : parseFloat(rank[position_num]);
                    }
                    offer = this.$common.toFormat2dot(offer + 0.01);
                    if (Number(amount) < Number(offer)) {
                        flag = true;
                        break;
                    }
                    //根据排名算出展现数
                    let show_num = 0;
                    /!*console.log('店铺的日预算', this.moduleTemplate);*!/
                    //当前计划的设置的时间个数
                    let time_times = Number(time_num_arr[kwItem.plan_id]);
                    if (position_num === 1) {
                        show_num = kwItem.left_show_num;
                    } else if (position_num > 1 && position_num <= 10) {
                        let [kl_show_num, kl_total_cost] = this.recursion(kwItem.left_show_num, position_num, rank, time_times);
                        show_num = kl_show_num;
                        kwItem.left_show_num -= Math.floor(kl_total_cost);
                    } else if (position_num > 10 && position_num <= 20 ) {
                        //获取前10名的浏览量消耗
                        let [l_ten_show_num, l_ten_total_cost] = this.recursion(kwItem.left_show_num * 0.5, Number(position_num - 10), rank, time_times);
                        /!*let [l_ten_show_num, l_ten_total_cost] = this.recursion(kwItem.left_show_num,10, rank, time_times);
                        let half_search_num = Math.floor(kwItem.search_num * 0.5);
                        let left_total_num = Number(kwItem.search_num) - Number(l_ten_total_cost);
                        if (left_total_num <= 0) {
                            continue;
                        }*!/
                        show_num = Math.floor(l_ten_show_num);
                        kwItem.left_show_num -= Math.floor(l_ten_total_cost);
                        /!*if (half_search_num <= left_total_num) {
                            let [half_ten_show_num, half_ten_total_cost] = this.recursion(half_search_num, Number(position_num - 10), rank, time_times);
                            show_num = half_ten_show_num;
                        } else {
                            let [le_show_num, le_ten_total_cost] = this.recursion(left_total_num, Number(position_num - 10), rank, time_times);
                            show_num = le_show_num;
                        }*!/
                    } else if (position_num > 20 && position_num <= 30) {
                        //获取前20名的浏览量消耗
                        /!*let [arr_show_num, arr_total] = this.recursion(kwItem.left_show_num, 20, rank, time_times);
                        let  left_total_num = Number(kwItem.left_show_num) - Number(arr_total);
                        if (left_total_num <= 0) {
                            continue;
                        }
                        left_total_num = Math.floor(left_total_num);
                        let half_search_num = Math.floor(kwItem.search_num * 0.3);
                        if (half_search_num <= left_total_num) {
                            let [tmp_show_num, tmp_total] = this.recursion(half_search_num, Number(position_num - 20), rank, time_times)
                            show_num = tmp_show_num;
                        } else {
                            let [tmp_show_num, tmp_total] = this.recursion(left_total_num, Number(position_num), rank, time_times);
                            show_num = tmp_show_num;
                        }*!/
                        let [_left_show_num, _left_total_cost] = this.recursion(kwItem.left_show_num * 0.3, Number(position_num - 20), rank, time_times);
                        show_num = Number(_left_show_num);
                        kwItem.left_show_num -= Math.floor(_left_total_cost);
                    } else if (position_num > 30 && position_num <= 50) {
                        //获取前30名的浏览消耗
                        // console.log('关键词剩余：', kwItem.left_show_num);
                        /!*let [tmp_show_num, tmp_total_cost] = this.recursion(kwItem.left_show_num, 30, rank, time_times);
                        let left_total_num = kwItem.left_show_num - Number(tmp_total_cost);
                        // console.log('@@##',left_total_num);
                        if (left_total_num <= 0) {
                            continue;
                        }
                        left_total_num = Math.floor(left_total_num);
                        let half_search_num = Math.floor(kwItem.search_num * 0.1);
                        if (half_search_num <= left_total_num) {
                            let [h_show_num, h_total_cost] = this.recursion(half_search_num, Number(position_num - 30), rank, time_times);
                            show_num = h_show_num;
                        } else {
                            let [l_show_num, l_total_cost] = this.recursion(left_total_num, Number(position_num - 30), rank, time_times);
                            show_num = l_show_num;
                        }*!/
                        let [l_show_num, l_total_cost] = this.recursion(Math.floor(kwItem.left_show_num * 0.1), Number(position_num - 30), rank, time_times);
                        show_num = Math.floor(l_show_num);
                        kwItem.left_show_num -= l_total_cost;
                    } else if (position_num > 50) {
                        show_num = 0;
                    }
                    if (show_num <= 0) {
                        continue;
                    }
                    //根据展现数和点击率算出预测的点击数
                    let office_click_num = Math.floor(show_num * (click_rate_tmp/100));
                    //如果获得的点击数小于1则也直接跳出循环
                    if (office_click_num === 0 || isNaN(office_click_num) || office_click_num < 0) {
                        continue;
                    }
                    //换算下一个时间的展现数
                    //判断出价是否为零
                    let stu_click_num = 0;
                    if (offer < 0) {
                        this.$message({
                            type: 'warning',
                            message: '出价不能小于0',
                            duration: 1000
                        });
                        return;
                    }

                    if (offer !== 0 && offer > 0) {
                        //判断是否为匀速
                        if (tablePlan[kwItem.plan_id].hour_cost && tablePlan[kwItem.plan_id].hour_cost > 0) {
                            if (tablePlan[kwItem.plan_id].hour_cost > amount) {
                                flag = true;
                                break;
                            }
                            //判断可用日预算余额是否大于匀速每个时间的花费
                            if (tablePlan[kwItem.plan_id].day_cost >= tablePlan[kwItem.plan_id].hour_cost) {

                                let cost_stu = 0;
                                //根据每个时间的花费以及出价算出点击数
                                stu_click_num = Math.floor(tablePlan[kwItem.plan_id].hour_cost / offer);
                                //对比预算的点击数 > 学生该时段的限制点击数
                                if (office_click_num > stu_click_num) {
                                    tablePlan[kwItem.plan_id].click_num += stu_click_num;
                                    cost_stu = this.$common.toFormat2dot(stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + Math.round(show_num);
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(stu_click_num * tran_rate_tmp/100);

                                    kwTable[kwItem.id].tran_num += Math.floor(stu_click_num * (tran_rate_tmp/100));
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + Math.round(show_num);
                                    kwTable[kwItem.id].click_num += stu_click_num;
                                    //关键词的搜索量扣除已消耗的展现数
                                    kwItem.left_show_num -= stu_click_num;
                                } else {
                                    tablePlan[kwItem.plan_id].click_num += office_click_num;
                                    cost_stu = this.$common.toFormat2dot(office_click_num * offer);
                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + show_num;
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(office_click_num * tran_rate_tmp/100);

                                    kwTable[kwItem.id].tran_num += Math.floor(office_click_num * (tran_rate_tmp/100));
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + show_num;
                                    kwTable[kwItem.id].click_num += office_click_num;
                                    //关键词的搜索量扣除已消耗的展现数
                                    kwItem.left_show_num -= office_click_num;
                                }
                                tablePlan[kwItem.plan_id].total_cost += this.$common.toFormat2dot(cost_stu);
                                tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);


                                kwTable[kwItem.id].total_cost += this.$common.toFormat2dot(cost_stu);
                                kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);
                                if (amount < cost_stu) {
                                    flag = true;
                                    break;
                                }
                                amount = this.$common.toFormat2dot(amount - cost_stu);
                                tablePlan[kwItem.plan_id].day_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].day_cost - cost_stu);
                            } else {
                                if (Number(tablePlan[kwItem.plan_id].day_cost) < 0 || Number(tablePlan[kwItem.plan_id].day_cost) < Number(offer)) {
                                    flag = true;
                                    continue;
                                }
                                //如果不够消耗就向下取整
                                stu_click_num = Math.floor(tablePlan[kwItem.plan_id].day_cost / offer);
                                if (office_click_num > stu_click_num) {
                                    tablePlan[kwItem.plan_id].click_num += stu_click_num;
                                    if (amount < this.$common.toFormat2dot(stu_click_num * offer)) {
                                        flag = true;
                                        break;
                                    }
                                    //余额和日预算余额扣除花费
                                    amount -= this.$common.toFormat2dot(stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].day_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].day_cost - stu_click_num * offer);
                                    //总费用累加
                                    tablePlan[kwItem.plan_id].total_cost += this.$common.toFormat2dot(stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);

                                    kwTable[kwItem.id].click_num += stu_click_num;
                                    kwTable[kwItem.id].total_cost += this.$common.toFormat2dot(stu_click_num * offer);
                                    kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);
                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + Math.round(show_num);
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + Math.round(show_num);
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(stu_click_num * tran_rate_tmp/100);
                                    kwTable[kwItem.id].tran_num += Math.floor(stu_click_num * (tran_rate_tmp/100));
                                    kwItem.left_show_num -= stu_click_num;
                                    continue;
                                } else {
                                    tablePlan[kwItem.plan_id].click_num += office_click_num;
                                    if (amount < this.$common.toFormat2dot(office_click_num * offer)) {
                                        flag = true;
                                        break;
                                    }
                                    //余额和日预算余额扣除花费
                                    amount -= this.$common.toFormat2dot(office_click_num * offer);
                                    tablePlan[kwItem.plan_id].day_cost -= this.$common.toFormat2dot(office_click_num * offer);
                                    //总费用累加
                                    tablePlan[kwItem.plan_id].total_cost += this.$common.toFormat2dot(office_click_num * offer);
                                    tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);

                                    kwTable[kwItem.id].click_num += office_click_num;
                                    kwTable[kwItem.id].total_cost += this.$common.toFormat2dot(office_click_num * offer);
                                    kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);
                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + show_num;
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + show_num;
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(office_click_num * tran_rate_tmp/100);
                                    kwTable[kwItem.id].tran_num += Math.floor(office_click_num * (tran_rate_tmp/100));
                                    kwItem.left_show_num -= office_click_num;
                                }
                            }
                        } else {
                            //尽快投放：有每日预算和无每日预算
                            //根据官方的点击数算出大致花费
                            let office_cost = this.$common.toFormat2dot(office_click_num * offer);
                            if (tablePlan[kwItem.plan_id].daily_budget) {//有每日预算
                                if (tablePlan[kwItem.plan_id].day_cost < 0) {
                                    flag = true;
                                    continue;
                                }
                                //在判断每日预算是否够消耗
                                if (tablePlan[kwItem.plan_id].day_cost >= office_cost) {
                                    tablePlan[kwItem.plan_id].click_num += office_click_num;
                                    tablePlan[kwItem.plan_id].day_cost -= office_cost;
                                    if (amount < office_cost) {
                                        flag = true;
                                        break;
                                    }
                                    amount = this.$common.toFormat2dot(amount - office_cost);
                                    tablePlan[kwItem.plan_id].total_cost += office_cost;
                                    tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);

                                    kwTable[kwItem.id].click_num += office_click_num;
                                    kwTable[kwItem.id].total_cost += office_cost;
                                    kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);
                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + show_num;
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + show_num;
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(office_click_num * tran_rate_tmp/100);
                                    kwTable[kwItem.id].tran_num += Math.floor(office_click_num * (tran_rate_tmp/100));
                                    kwItem.left_show_num -= office_click_num;
                                } else {
                                    //每日的预算小于出价则跳过循环
                                    if (Number(tablePlan[kwItem.plan_id].day_cost) < Number(offer)) {
                                        flag = true;
                                        continue;
                                    }
                                    stu_click_num = Math.floor(tablePlan[kwItem.plan_id].day_cost  / offer);
                                    tablePlan[kwItem.plan_id].click_num += stu_click_num;
                                    tablePlan[kwItem.plan_id].day_cost -= this.$common.toFormat2dot(stu_click_num * offer);
                                    if (amount < this.$common.toFormat2dot(stu_click_num * offer)) {
                                        flag = true;
                                        break;
                                    }
                                    amount = this.$common.toFormat2dot(amount - stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].total_cost += this.$common.toFormat2dot(stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);

                                    kwTable[kwItem.id].click_num += stu_click_num;
                                    kwTable[kwItem.id].total_cost += this.$common.toFormat2dot(stu_click_num * offer);
                                    kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);

                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + Math.round(show_num);
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + Math.round(show_num);
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(stu_click_num * tran_rate_tmp/100);
                                    kwTable[kwItem.id].tran_num += Math.floor(stu_click_num * (tran_rate_tmp/100));
                                    kwItem.left_show_num -= stu_click_num;
                                    // break;
                                }
                            } else {
                                //无每日预算
                                if (amount >= office_cost) {
                                    tablePlan[kwItem.plan_id].click_num += office_click_num;
                                    amount = this.$common.toFormat2dot(amount - office_cost);
                                    tablePlan[kwItem.plan_id].total_cost += office_cost;
                                    tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);

                                    kwTable[kwItem.id].click_num += office_click_num;
                                    kwTable[kwItem.id].total_cost += office_cost;
                                    kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);
                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + show_num;
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + show_num;
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(office_click_num * tran_rate_tmp/100);
                                    kwTable[kwItem.id].tran_num += Math.floor(office_click_num * (tran_rate_tmp/100));
                                    kwItem.left_show_num -= office_click_num;
                                } else {
                                    if (Number(amount) < Number(offer)) {
                                        flag = true;
                                        break;
                                    }
                                    stu_click_num = Math.floor(amount / offer);
                                    tablePlan[kwItem.plan_id].click_num += stu_click_num;
                                    amount = this.$common.toFormat2dot(amount - stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].total_cost += this.$common.toFormat2dot(stu_click_num * offer);
                                    tablePlan[kwItem.plan_id].total_cost = this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost);


                                    kwTable[kwItem.id].click_num += stu_click_num;
                                    kwTable[kwItem.id].total_cost += this.$common.toFormat2dot(stu_click_num * offer);
                                    kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);

                                    tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + Math.round(show_num);
                                    kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + Math.round(show_num);
                                    tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(stu_click_num * tran_rate_tmp/100);
                                    kwTable[kwItem.id].tran_num += Math.floor(stu_click_num * (tran_rate_tmp/100));
                                    kwItem.left_show_num -= stu_click_num;
                                }

                            }
                        }
                    } else {
                        tablePlan[kwItem.plan_id].click_num += office_click_num;
                        tablePlan[kwItem.plan_id].total_cost += this.$common.toFormat2dot(tablePlan[kwItem.plan_id].total_cost + (office_click_num * offer));

                        kwTable[kwItem.id].click_num += office_click_num;
                        kwTable[kwItem.id].total_cost += this.$common.toFormat2dot(office_click_num * offer);
                        kwTable[kwItem.id].total_cost = this.$common.toFormat2dot(kwTable[kwItem.id].total_cost);
                        tablePlan[kwItem.plan_id].show_num = tablePlan[kwItem.plan_id].show_num + show_num;
                        kwTable[kwItem.id].show_num = kwTable[kwItem.id].show_num + show_num;
                        tablePlan[kwItem.plan_id].tran_num = tablePlan[kwItem.plan_id].tran_num + Math.floor(office_click_num * tran_rate_tmp/100);
                        kwTable[kwItem.id].tran_num += Math.floor(office_click_num * (tran_rate_tmp/100));
                        kwItem.left_show_num -= office_click_num;
                        // amount -= this.$common.toFormat2dot(office_click_num * offer); 出价为零
                    }
                }
            }
            //换算关键词数据
            let kw_post_data = [];
            _.each(kwTable, (item) => {
                let tmp_data = {};
                if (item) {
                    tmp_data.id = item.id;
                    tmp_data.total_cost = item.total_cost;
                    tmp_data.ep_id = item.ep_id;
                    tmp_data.click_num = item.click_num;
                    tmp_data.show_num = item.show_num;
                    tmp_data.avg_show_rank = Math.floor(item.position_num / item.time_count);
                    let tran_num = item.tran_num;
                    let goods_info = _.find(goodsQueue, { plan_id: item.ep_id });
                    tmp_data.total_order_amount = Number(goods_info.goods_price) * tran_num;
                    tmp_data.click_rate = Number(item.click_num) === 0 ? 0 :this.$common.toFormat2dot((item.click_num / item.show_num) * 100);
                    tmp_data.tran_rate = Number(item.tran_num) === 0 ? 0 : this.$common.toFormat2dot((item.tran_num / item.click_num) * 100);
                    if (item.total_cost !== 0) {
                        tmp_data.roi = Number((tmp_data.total_order_amount / tmp_data.total_cost).toFixed(2));
                    } else {
                        tmp_data.roi = tmp_data.total_order_amount;
                    }
                    kw_post_data.push(tmp_data);
                }
            });
            // console.log('tablePlan',tablePlan);return;
            //更新余额
            this.$emit('upAmount', amount);
            let post_plan_data = [];
            _.each(tablePlan, (item) => {
                if (item) {
                    let tmp = {
                        id: item.id,
                        click_rate: (Number(item.click_num) === 0) ? 0 : this.$common.toFormat2dot((item.click_num / item.show_num) * 100) ,
                        tran_rate: (Number(item.tran_num) === 0) ? 0 : this.$common.toFormat2dot((item.tran_num / item.click_num) * 100),
                        total_cost: item.total_cost,
                        click_num: item.click_num,
                        show_num: item.show_num,
                    }
                    post_plan_data.push(tmp);
                }
            });
            this.promotionPlanList = _.map(this.promotionPlanList, (item) => {
                let tmp = _.find(post_plan_data, {id: item.id});
                item.total_cost = tmp.total_cost;
                item.click_num = tmp.click_num;
                item.click_rate = this.$common.toFormat2dot(tmp.click_rate);
                item.tran_rate = this.$common.toFormat2dot(tmp.tran_rate);
                item.show_num = tmp.show_num;
                return item;
            });
            this.$http.axiosPost(this.$api.ep_stopep, { plan: post_plan_data, keywords: kw_post_data, amount}, (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },*/
        /*
        * @param search_num 关键词展现量
        * @param position_num 排名数
        * @param total 总的展现数
        * @param store_offer  每个店铺的出价集合
        * @param time_nums 该计划投放的时间个数
        * */
        recursion(search_num, position_num, store_offer = [], time_nums, total=0, flag=1) {
            if (Number(flag) === Number(position_num)) {
                return [search_num, total];
            }
            //第一名的店铺的每个时间的出价
            let index = Number(flag) - 1;
            let hour_offer = Math.floor(this.store_bugget[index] / time_nums);
            this.store_bugget[index] = Number(this.store_bugget[index]) - hour_offer;
            let click_num = Math.floor(hour_offer / Number(store_offer[index]));
            let show_num = search_num - click_num;
            total += click_num;
            if (Number(show_num) <= 0) {
                return [0, total];
            }
            flag++;
            if (position_num === flag) {
                return [show_num, total];
            } else {
                return this.recursion(show_num, position_num, store_offer, time_nums, total, flag);
            }
            /*let show_num = Math.floor(search_num * (1 - click_rate));
            total += show_num;
            num--;
            if (num === 1) {
                return [show_num, total];
            } else {
                return this.recursion(show_num, click_rate, num, total);
            }*/
        },
        zoneRate(value=0) {
            if (value >= 1 && value <= 4) {
                return 1;
            } else if (value >= 4 && value <= 6) {
                return 2;
            } else if (value >= 7) {
                return 3;
            } else if (value >= -4 && value <= -1) {
                return -1;
            } else if (value > -7 && value <= -4) {
                return -2;
            } else if (value <= -7) {
                return -3;
            } else {
                return 0;
            }
        },
        //获取推广计划列表
        getPromotionList() {
            let param = {
                type: 'page',
                limit: this.listPages.eachPageNum,
                page: this.listPages.currentPageNum
            }
            this.$http.axiosGetBy(this.$api.SDepList, param, (res) => {
                if (res.code === 200) {
                    //创建一个计划出现两个列表的问题处理，过滤掉
                    /*let arr = [];
                    _.each(res.data.data, (planItem) => {
                        if (arr.length === 0) {
                            arr.push(planItem.id);
                        } else {
                            let arrTmp = [planItem.id];
                            arr = _.union(arr, arrTmp);
                        }
                    });
                    _.each(arr, (id) => {
                        let tmp = _.find(res.data.data, { id: id });
                        this.promotionPlanList.push(tmp);
                    });*/
                    this.promotionPlanList = res.data.data;
                    if (this.promotionPlanList.length === 0) {
                        this.$emit('upAmount', Number(this.$lockr.get('amount')));
                    }
                    if (res.data.count !== undefined) {
                        this.leftCount = res.data.count;
                        this.$lockr.set('tg_count', this.leftCount);
                    }

                    this.listPages.total = res.data.total;
                    //统计计划日预算
                     this.planTotalCost = _.map(this.promotionPlanList, (item) => {
                         let tmp = {
                             plan_id: item.id,
                             daily_budget: Number(item.daily_budget)
                         }
                         return tmp;
                    });
                    this.setPlanTotalCost(this.planTotalCost);
                    if (this.$common.isCompetion()) {
                        let menus = this.menus;
                        let currentStatus = menus[2].children[4].status;
                        if (Number(currentStatus) === 0) {
                            this.operation_updateStatus(2, 4, 1);
                        }
                    }
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        //新建计划
        createPlan() {
            if (this.promotionPlanList.length >= 5) {
                this.$message({
                    type: 'warning',
                    message: '推广计划最多只能建5个',
                    duration: 2000
                });
                return;
            }
            this.$router.push({
                path:'/student/operationpromotion/searchads/createplanstepone'
            })
        },
        //删除（可以多选）
        delToggle() {
            if (this.deleteCollections.length === 0) {
                this.$message.warning('请选择要删除的计划');
                return ;
            }
            let ids = _.map(this.deleteCollections, (item) => {
                return item.id;
            });
            this.$confirm('此操作将永久删除已选的计划，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deletePlans(ids);
            }).catch(() => {
                console.log("取消操作");
            });

        },
        deletePlans(ids) {
            let param = {};
            if (ids.length > 1) {
                param.ids = ids.join(',');
            } else {
                param.ids = ids;
            }
            this.$http.axiosGetBy(this.$api.ep_delep, {ids}, (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            this.getPromotionList();
                            this.$http.axiosGet(this.$api.ep_getsb, (res) => {
                                if (res.code === 200) {
                                    this.$emit('upAmount', res.data.stu_balance);
                                    window.location.reload();
                                } else {
                                    this.$message.warning(res.msg);
                                }
                            }, (err) => {
                                this.$common.axiosErrorMsg(err);
                            })
                        }
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        //结束推广
        endToggle() {
            const planNum = this.deleteCollections.length
            if (!planNum) {
                this.$message.warning("请选择要结束的计划")
                return
            }
            this.$confirm(`您确定要结束${planNum}个推广计划吗？`, '操作确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'btn-red',
                type: 'warning',
            }).then(() => {
                this.endingPromotion();
            }).catch((err) => {
                console.log(err);
                if (err !== 'cancel') {
                    this.$message({
                        type: 'warning',
                        message: '正在请求数据，请在页面刷新后重新结束推广，谢谢！',
                        onClose: () => {
                            // window.location.reload();
                        }
                    });
                } else {
                    this.$message.info('已取消操作');
                }

            });
        },
        //修改推广计划名称
        clickName(row) {
            this.isShow = row.id;
            //点开输入框  聚焦
            setTimeout(()=> {
                this.$refs[`nameInput_${row.id}`].focus();
            }, 100);
        },
        //失焦事件
        validateNum(val) {
            if (val === '') {
                this.$message.error('请输入推广计划名称');
                return
            } else {
                let param = {
                    id: val.id,
                    project_name: val.project_name,
                }
                this.$http.axiosGetBy(this.$api.ep_editep, param, (res) => {
                    if (res.code === 200) {
                        this.isShow = null;
                        this.$notify({
                            title: '',
                            message: '修改成功！',
                            duration: 1000,
                            type: 'success',
                            onClose: () => {
                                this.getPromotionList();
                            }
                        });
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            }

        },
        //修改时段 | 实时折扣系数
        editTime(row) {
            this.timeDrawer = true;
            this.rowTmp = row;
            this.theCurrentPlanId = row.id;
            let time_set = JSON.parse(this.rowTmp.time_set);
            if (time_set.length === 24) {
                this.timeRadio = 1;
            } else {
                this.timeRadio = 2;
            }
            // console.log('time_set',time_set);
            this.oneToSix = _.map(this.oneToSix, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type) + 1});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });
            this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type) + 1});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });
            this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type) + 1});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });

            this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                let item_tmp = _.find(time_set, {time: Number(item.type) + 1});
                if (item_tmp) {
                    item.launch = 1;
                    item.discount = item_tmp.discount;
                } else {
                    item.launch = 0;
                    item.discount = 0;
                }
                return item;
            });

            return;
        },
        selectShowWay(val){
            this.timeRadio = val;
            if (Number(val) === 2) {
                this.oneToSix = _.map(this.oneToSix, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
            } else {
                this.oneToSix = _.map(this.oneToSix, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
            }
        },
        setTime(name, index, discount) {
            if (Number(discount) === 0 && this.radio5 === 1) {
                this.$message.warning('折扣不能设置为0！');
                return;
            }
            switch (name) {
                case 'oneToSix':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.oneToSix, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.oneToSix, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.oneToSix, index, discount, 0);
                    }
                    break;
                case 'sixToTwelve':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.sixToTwelve, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.sixToTwelve, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.sixToTwelve, index, discount, 0);
                    }
                    break;
                case 'TwelveToEighteen':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.TwelveToEighteen, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.TwelveToEighteen, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.TwelveToEighteen, index, discount, 0);
                    }
                    break;
                case 'EighteenToTwentyFour':
                    if (this.radio5 === 1) {
                        this.setTimeZoneParam(this.EighteenToTwentyFour, index, discount, 1);
                    } else if (this.radio5 === 2) {
                        this.setTimeZoneParam(this.EighteenToTwentyFour, index, 100, 1);
                    } else if (this.radio5 === 3) {
                        this.setTimeZoneParam(this.EighteenToTwentyFour, index, discount, 0);
                    }
                    break;
            }
            let isAllTime = 0;
            _.each(this.oneToSix, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            _.each(this.sixToTwelve, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            _.each(this.TwelveToEighteen, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            _.each(this.EighteenToTwentyFour, (item) => {
                if (Number(item.launch)) {
                    isAllTime++;
                }
            });
            this.timeRadio = isAllTime === 24 ? 1 : 2;
            this.clickFormData.show = false;
        },
        setTimeZoneParam(obj, index, discount, launch) {
            obj[index].discount = Number(discount);
            obj[index].launch = launch;
        },
        clickDialog(item, evt) {
            if (Number(item.discount) === 100) {
                this.radio5 = 2;
            } else if (Number(item.launch === 1)) {
                this.radio5 = 1;
            } else {
                this.radio5 = 3;
            }
            let winClientWidth = document.body.clientWidth;
            this.closeHoverD();
            this.clickFormData.timezone = item.timezone;
            this.clickFormData.discount = Number(item.discount);
            this.clickFormData.name = item.name;
            this.clickFormData.show = true;
            this.clickFormData.index = item.index;
            let x = evt.clientX + 20;
            let y = evt.clientY + 20;
            if(winClientWidth - 246 < x){
                this.clickFormData.style = {
                    left: `${winClientWidth - 246}px`,
                    top: `${y}px`
                }
            }
            else {
                this.clickFormData.style = {
                    left: `${x}px`,
                    top: `${y}px`
                }
            }
        },
        hoverDialog(item, evt) {
            let winClientWidth = document.body.clientWidth;
            this.hoverFormData.timezone = item.timezone;
            this.hoverFormData.discount = item.discount;
            this.hoverFormData.show = true;
            let x = evt.clientX + 4;
            let y = evt.clientY - 100;
            if(winClientWidth - 210 < x){
                this.hoverFormData.style = {
                    left: `${winClientWidth - 210 }px`,
                    top: `${y}px`
                }
            } else {
                this.hoverFormData.style = {
                    left: `${x}px`,
                    top: `${y}px`
                }
            }
        },
        closeHoverD() {
            this.hoverFormData.show = false;
        },
        //修改自定义预算
        editDailyBudget(row) {
            this.customDrawer = true;
            this.customBudget = Number(row.daily_budget);
            this.theCurrentPlanId = row.id;
            this.rowTmp = row;
        },
        validatePlanCost(plan_id, daily_bugget) {
            let planId = Number(plan_id);
            let dailyBugget = Number(daily_bugget);
            let amount = this.$lockr.get('amount');
            if (dailyBugget > amount) {
                this.$message({
                    type: 'warning',
                    message: '日预算不能超过设定的推广余额',
                    duration: 2000
                });
                return false;
            }
            let total = 0;
            let total_cost_arr = _.map(this.planTotalCost, (_val) => {
                if (Number(_val.plan_id) === planId) {
                    total += dailyBugget;
                    _val.daily_budget = dailyBugget;
                } else {
                    total += Number(_val.daily_budget);
                }
                return _val;
            });
            // console.log('@@', total_cost_arr);
            if (total > amount) {
                this.$message({
                    type: 'warning',
                    message: '计划的日预算总和不能超过设定的推广余额',
                    duration: 2000
                });
                return false;
            }
            this.setPlanTotalCost(total_cost_arr);
            return true;
        },
        setPlanTotalCost(plan_total_cost) {
            this.planTotalCost = plan_total_cost;
            this.$lockr.set('plan_total_cost', this.planTotalCost);
        },
        //保存预算
        saveDailyBudget() {
            if (isNaN(Number(this.customBudget)) || Number(this.customBudget) === 0) {
                this.$message({
                    type: 'warning',
                    message: '日预算不能为空或者为0',
                    duration: 1000
                });
                return;
            }
            //验证当前修改之后的日预算后，计划中的总日预算是否会超过给定的推广余额
            let validateRes = this.validatePlanCost(this.theCurrentPlanId, this.customBudget);
            if (!validateRes) {
                return;
            }
            this.customDrawer = false;
            this.rowTmp.daily_budget = this.customBudget;
            let param = {
                id: this.theCurrentPlanId,
                daily_budget: Number(this.customBudget)
            }
            this.$http.axiosGetBy(this.$api.ep_editep, param, (res) => {
                if (res.code === 200) {
                    this.$notify({
                        type: 'success',
                        duration: 1000,
                        message: `每日预算${res.msg}`,
                        onClose: () => {
                            this.getPromotionList();
                        }
                    });
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                    });
                }
            }, err => {
                this.$common.axiosErrorMsg(err);
            })
        },
        //删除（单条）
        delBtn(id) {
            this.$confirm('您确定要删除该推广计划吗？', '操作确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'btn-red',
                type: 'warning',
            }).then(() => {
                let ids = [id];
                this.deletePlans(ids);
            }).catch(() => {
                this.$message.info('已取消删除！');
            });
        },
        //分页
        handleSizeChange(val) {
            this.listPages.eachPageNum = val;
            this.getPromotionList();
        },
        handleCurrentChange(val) {
            this.listPages.currentPageNum = val;
            this.getPromotionList();
        }
    }
}